import React, { FC } from 'react';

import { Card } from './components/card';
import { LocalizationContext } from '../../localization';

import './styles.css';

export const CardsSection: FC = () => {
  const titles = [
    { title: 'one', url: '/images/Character-working-laptop.png' },
    { title: 'two', url: '/images/Character-holding-tablet-device.png' },
    { title: 'three', url: '/images/character-jumping.png' }
  ];
  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <section className="cardsSection">
          <div className="divider"></div>
          <img className="cardsSection__ellipse" src="/images/Ellipse.png" alt="ellipse" />
          <img className="cardsSection__cloud" src="/images/cloud-with-cup.svg" alt="cloud" />
          <div className="cardsSection__wrapper">
            <h2 id="howItWork" className="h2">
              {getWord('three_steps')}
            </h2>
            <div className="cardsSection__content">
              {titles.map((card, index) => (
                <Card key={card.title} title={getWord(card.title)} url={card.url} index={index} />
              ))}
            </div>
          </div>
        </section>
      )}
    </LocalizationContext.Consumer>
  );
};
