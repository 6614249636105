import { FC } from 'react';

import './styles.css';

interface Props {
  title: string;
  url: string;
  index: number;
}
export const Card: FC<Props> = ({ title, url, index }: Props) => {
  return (
    <div className="card">
      <div className="card__title">
        <div className="card__index">{index + 1}.</div>
        <div className="title">{title}</div>
      </div>
      <img className="card__img" src={url} alt="girl" />
    </div>
  );
};
