export const defaultDictionary = {
  how_it_work: {
    ca: 'Com funciona',
    en: 'How it works',
    es: 'Cómo funciona',
    fr: 'Comment ça marche',
    ru: 'Как это работает'
  },
  h1: {
    ca: 'Una aplicació per a la cerca ràpida de clients',
    en: 'Application for quick customer search',
    es: 'Aplicación para la búsqueda rápida de clientes',
    fr: 'Application pour la recherche rapide de clients',
    ru: 'Приложение для быстрого поиска клиентов'
  },
  find_clients: {
    ca: 'troba clients per a empreses, artesans privats i contractistes',
    en: 'find clients for companies, private craftsmen and contractors',
    es: 'encontrar clientes para empresas, artesanos privados y contratistas',
    fr: 'trouver des clients pour les entreprises, les artisans privés et les entrepreneurs',
    ru: 'находим клиентов для компаний, частных мастеров и подрядчиков'
  },
  bubbles_0: {
    ca: 'Hola, podeu ajudar-me amb la neteja de casa demà a la nit?',
    en: 'Hello, could you please help me with house cleaning tomorrow night?',
    es: 'Hola, ¿podría ayudarme con la limpieza de la casa mañana por la noche?',
    fr: "Bonjour, pourriez-vous m'aider à faire le ménage demain soir ?",
    ru: 'Здравствуйте, не могли бы вы помочь мне с уборкой дома завтра вечером?'
  },
  bubbles_1_1: {
    ca: 'Hola, sí segur!',
    en: 'Hello, Yes sure!',
    es: 'Hola, ¡Sí, claro!',
    fr: 'Bonjour, Oui bien sûr!',
    ru: 'Здравствуйте, да, конечно!'
  },
  bubbles_1_2: {
    ca: 'Si us plau, escriu-me la teva adreça',
    en: 'Please write me your address',
    es: 'Por favor, escríbeme su dirección',
    fr: "Veuillez m'indiquer votre adresse",
    ru: 'Пожалуйста, напишите мне ваш адрес.'
  },
  google_play: {
    ca: 'descarregar',
    en: 'upload to',
    es: 'subir a',
    fr: 'télécharger vers',
    ru: 'загрузить в'
  },
  app_store: {
    ca: 'descarregar',
    en: 'download to',
    es: 'descargar desde',
    fr: 'télécharger à partir de',
    ru: 'скачать из'
  },
  three_steps: {
    ca: 'Només 3 passos per a noves comandes!',
    en: 'Only 3 steps to new orders!',
    es: 'Sólo 3 pasos para nuevos pedidos',
    fr: 'Seulement 3 étapes pour de nouvelles commandes !',
    ru: 'Всего 3 шага до новых заказов!'
  },
  one: {
    ca: "Descarrega l'aplicació!",
    en: 'Download the app!',
    es: 'Descargar la aplicación',
    fr: "Télécharger l'application",
    ru: 'Загрузите приложение !'
  },
  two: {
    ca: "Registra't i completa el teu perfil",
    en: 'Register and complete your profile',
    es: 'Regístrese y complete su perfil',
    fr: "S'inscrire et compléter son profil",
    ru: 'Пройдите регистрацию и заполните профиль'
  },
  three: {
    ca: "Comunicar-se amb els clients directament a l'aplicació",
    en: 'Communicate with customers right in the app',
    es: 'Comuníquese con sus clientes directamente desde la aplicación',
    fr: "Communiquer avec les clients directement dans l'application",
    ru: 'Общайтесь с клиентами прямо в приложении'
  },
  money: {
    ca: 'Sense despeses de publicitat',
    en: 'Without advertising costs',
    es: 'Sin costes de publicidad',
    fr: 'Pas de frais de publicité',
    ru: 'Никаких затрат на рекламу'
  },
  time: {
    ca: 'Més temps per als teus clients',
    en: 'More time for your customers',
    es: 'Más tiempo para sus clientes',
    fr: 'Plus de temps pour vos clients',
    ru: 'Больше времени для ваших клиентов'
  },
  plans: {
    ca: "Planifiqueu les reunions amb un mes d'antelació",
    en: 'Plan meetings one month in advance',
    es: 'Planificar las reuniones con un mes de antelación',
    fr: "Planifier les réunions un mois à l'avance",
    ru: 'Планируйте встречи на месяц вперед'
  },
  customers: {
    ca: 'Sense la recerca rutinària de clients',
    en: 'Without the routine search for customers',
    es: 'Sin la búsqueda rutinaria de clientes',
    fr: 'Sans la recherche systématique de clients',
    ru: 'Никакого рутинного поиска клиентов'
  },
  nice_doing_business: {
    ca: 'Un plaer fer negocis amb tu!',
    en: 'Nice doing business with you!',
    es: 'Fue un placer hacer negocios con ustedes.',
    fr: "C'est un plaisir de travailler avec vous!",
    ru: 'Приятно иметь с вами дело!'
  },
  privacy_policy: {
    ca: '© 2023 BocaBoca. Tots els drets reservats Política de privacitat',
    en: '© 2023 BocaBoca. All rights reserved Privacy Policy',
    es: '© 2023 BocaBoca. Reservados todos los derechos Política de Privacidad',
    fr: '© 2023 BocaBoca. Tous droits réservés Politique de confidentialité',
    ru: '© 2023 BocaBoca. Все права защищены Политика конфиденциальности'
  }
};
