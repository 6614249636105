import React from 'react';
import './App.css';
import './index.css';
import { TopSection } from './components/topSection';
import { Header } from './components/header';
import { CardsSection } from './components/cardsSection';
import { BenefitsSection } from './components/benefitsSection';
import { Footer } from './components/footer';

function App() {
  return (
    <>
      <img className="cloudMobileLeft" src="/images/cloudTopSectionMobileLeft.png" alt="cloud" />
      <img className="topSection__line" src="/images/lineHeader.png" alt="line" />
      <img className="topSection__lineMobile" src="/images/lineMobileTop.png" alt="line" />
      <Header />
      <TopSection />
      <div className='topSection__spacer'></div>
      <CardsSection />
      <BenefitsSection />
      <Footer />
    </>
  );
}

export default App;
