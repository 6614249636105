import React, { FC, useState } from 'react';

import { LocalizationContext } from '../../../../localization';
import env from '../../../../environment.json';

import './styles.css';

interface Props {
  toggleBurger: (close: boolean) => void;
}
export const BurgerMenu: FC<Props> = ({ toggleBurger }: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const toggleModalLanguages = () => {
    setIsDropdownOpen(!isDropdownOpen);
    document.getElementsByClassName('burgerMenu__dropdown')[0].classList.toggle('dropdownUp');
  };
  const openWeb = () => window.open(env.webURL);

  const openAppStore = () => window.open(env.appStore);

  const openGooglePlay = () => window.open(env.googlePlay);

  const openInstagram = () => window.open(env.instagramUrl);

  const goToChapter = () => {
    window.scrollTo({ top: 1100, behavior: 'smooth' });
    toggleBurger(false);
  };

  return (
    <LocalizationContext.Consumer>
      {({ getWord, languageList, changeLanguage, language }) => (
        <div className="burgerMenu">
          <div className="burgerMenu__logoWrapper">
            <img className="header__logo" src={'images/logo.png'} alt="logo" />
          </div>
          <div className="burgerMenu__body">
            <div className="burgerMenu__linksContainer">
              <div onClick={goToChapter} className="burgerMenu__link hover">
                <p>{getWord('how_it_work')}</p>
              </div>
              <div onClick={openWeb} className="burgerMenu__link hover">
                <p>Boca boca web</p>
              </div>
            </div>
            <div className="appWrapper">
              <div onClick={openAppStore} className="appWrapper__button hover">
                <img className="apple" src="/images/apple.svg" alt="apple" />
                <div className="titleContainer">
                  <p className="titleContainer__subtitle">{getWord('app_store')}</p>
                  <p className="titleContainer__name">App Store</p>
                </div>
              </div>
              <div onClick={openGooglePlay} className="appWrapper__button hover">
                <img className="google" src="/images/play.png" alt="Google Play" />
                <div className="titleContainer">
                  <p className="titleContainer__subtitle">{getWord('google_play')}</p>
                  <p className="titleContainer__name">Google Play</p>
                </div>
              </div>
            </div>
            <div onClick={toggleModalLanguages} className="burgerMenu__buttonWrapper">
              <div className="burgerMenu__buttonLanguage hover">
                <p className="burgerMenu__language">{language.label}</p>
                <img className="burgerMenu__dropdown" src="/images/dropdown.svg" alt="arrow" />
              </div>
              {isDropdownOpen && (
                <div className="languageModal">
                  {languageList.map(
                    lang =>
                      language.value !== lang.value && (
                        <p key={lang.value} className="hover" onClick={() => changeLanguage(lang.value)}>
                          {lang.label}
                        </p>
                      )
                  )}
                </div>
              )}
            </div>
            <div className="header__icons burgerMenu__icons">
              {/*<img src={'images/facebook.svg'} alt='facebook'/>*/}
              <img className="hover" onClick={openInstagram} src={'images/instagram.svg'} alt="instagram" />
            </div>
            <p className="burgerMenu__copyright">{getWord('privacy_policy')}</p>
          </div>
        </div>
      )}
    </LocalizationContext.Consumer>
  );
};
