import React, { createContext, useState, PropsWithChildren, useEffect } from 'react';
import { has } from 'lodash';

import { ILocalizationContext, ILocalizationLanguage } from './types';
import { defaultDictionary } from './dictionary';

const cLocalStorageKey = 'LocalizationContextProvider';
const cDefaultLanguageList = [
  { label: 'Русский', value: 'ru' },
  { label: 'English', value: 'en' },
  { label: 'Español', value: 'es' },
  { label: 'Català', value: 'ca' },
  { label: 'Français', value: 'fr' }
];

export const cDefaultLanguage = cDefaultLanguageList[1];

export const LocalizationContext = createContext<ILocalizationContext>({
  language: cDefaultLanguage,
  dictionary: {},
  languageList: [],
  changeLanguage: (_language: string) => {},
  getWord: (_key: string): string => ''
});

export const LocalizationContextProvider = ({ children }: PropsWithChildren) => {
  const [language, updateLanguage] = useState<ILocalizationLanguage>(cDefaultLanguage);
  const [dictionary, setDictionary] = useState<Record<string, Record<string, string>>>(defaultDictionary);

  const getWord = (key: string) => {
    let result = key;
    if (has(dictionary, key)) {
      result = dictionary[key][language.value];
    }
    return result;
  };

  const changeLanguage = (key: string) => {
    const language = cDefaultLanguageList.find(obj => obj.value === key);
    if (language != null) {
      updateLanguage(language);
      localStorage.setItem(cLocalStorageKey, language.value);
    }
  };

  const getDictionary = () => {
    let key = localStorage.getItem(cLocalStorageKey);
    changeLanguage(key != null ? key : cDefaultLanguage.value);
  };

  useEffect(() => {
    getDictionary();
  }, []);

  return (
    <LocalizationContext.Provider
      value={{
        language,
        dictionary,
        languageList: cDefaultLanguageList,
        changeLanguage,
        getWord
      }}>
      {children}
    </LocalizationContext.Provider>
  );
};
