import { FC } from 'react';

import env from '../../environment.json';
import { LocalizationContext } from '../../localization';

import './styles.css';

export const Footer: FC = () => {
  const openInstagram = () => window.open(env.instagramUrl);

  const openAppStore = () => window.open(env.appStore);

  const openGooglePlay = () => window.open(env.googlePlay);

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <footer className="footer">
          <img className="footer__cloud" src="/images/cloud3.png" alt="cloud" />
          <img className="footer__line" src="/images/lineFooter.png" alt="line" />
          <div className="footer__wrapper">
            <p className="footer__title">{getWord('nice_doing_business')}</p>
            <div className="appWrapper footer__appWrapper">
              <div onClick={openAppStore} className="appWrapper__button hover">
                <img className="apple" src="/images/apple.svg" alt="apple" />
                <div className="titleContainer">
                  <p className="titleContainer__subtitle">{getWord('app_store')}</p>
                  <p className="titleContainer__name">App Store</p>
                </div>
              </div>
              <div onClick={openGooglePlay} className="appWrapper__button hover">
                <img className="google" src="/images/play.png" alt="Google Play" />
                <div className="titleContainer">
                  <p className="titleContainer__subtitle">{getWord('google_play')}</p>
                  <p className="titleContainer__name">Google Play</p>
                </div>
              </div>
            </div>
            <div className="footer__logoWrapper">
              <img className="footer__logo" src="/images/logoSmall.svg" alt="logo" />
            </div>
            <p className="copyright">{getWord('privacy_policy')}</p>
            <div className="footer__icons">
              {/*<img src='/images/facebook.svg' alt='facebook'/>*/}
              <img className="hover" onClick={openInstagram} src="/images/instagram.svg" alt="instagram" />
            </div>
          </div>
        </footer>
      )}
    </LocalizationContext.Consumer>
  );
};
