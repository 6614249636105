import React, { FC, useState } from 'react';

import { BurgerMenu } from './components/burgerMenu';
import { LocalizationContext } from '../../localization';
import env from '../../environment.json';

import './styles.css';

export const Header: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isModalLanguageOpen, setIsModalLanguageOpen] = useState<boolean>(false);

  const toggleModalLanguages = () => {
    setIsModalLanguageOpen(!isModalLanguageOpen);
  };
  const toggleMenu = (close: boolean | null) => {
    if (close == null) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(false);
    }
    document.getElementsByTagName('body')[0].classList.toggle('overflow');
  };

  const openWeb = () => window.open(env.webURL);

  const openInstagram = () => window.open(env.instagramUrl);

  const goToChapter = () => {
    const chapter: any = document.getElementById('howItWork');
    chapter?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  return (
    <header className="header">
      {isOpen && <BurgerMenu toggleBurger={() => toggleMenu(false)} />}
      <div className="header__wrapper">
        <div className="header__burgerMenu hover" onClick={() => toggleMenu(null)}>
          {isOpen ? (
            <img className="close" src="/images/close.svg" alt="menu" />
          ) : (
            <img src="/images/burgerMenu.svg" alt="menu" />
          )}
        </div>
        <div className="header__logoWrapper">
          <img className="header__logo" src={'images/logo.png'} alt="logo" />
        </div>
        <div className="header__rightMenu">
          <div className="header__icons">
            <img className="hover" onClick={openInstagram} src={'images/instagram.svg'} alt="instagram" />
            {/*<img src={'images/facebook.svg'} alt='facebook'/>*/}
          </div>
          <LocalizationContext.Consumer>
            {({ getWord, languageList, changeLanguage, language }) => (
              <>
                <p onClick={goToChapter} className="textSmall hover">
                  {getWord('how_it_work')}
                </p>
                <div onClick={openWeb} className="header__button">
                  <div className="textSmall hover">bocaboca web</div>
                </div>
                <div onClick={toggleModalLanguages} className="header__languagesButtonWrapper hover">
                  <div className="header__languagesButton ">
                    <div className="text26Bold language">{language.value}</div>
                  </div>
                  {isModalLanguageOpen && (
                    <div className="header__languageModal">
                      {languageList.map(
                        lang =>
                          language.value !== lang.value && (
                            <p
                              key={lang.value}
                              onClick={() => changeLanguage(lang.value)}
                              className="language text26Bold hover">
                              {lang.value}
                            </p>
                          )
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </LocalizationContext.Consumer>
        </div>
      </div>
    </header>
  );
};
