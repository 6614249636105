import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { LocalizationContextProvider } from './localization';

import './index.css';
import './App.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <LocalizationContextProvider>
    <App />
  </LocalizationContextProvider>
);
