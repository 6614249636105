import { FC } from 'react';

import { LocalizationContext } from '../../localization';

import './styles.css';

export const BenefitsSection: FC = () => (
  <section className="benefitsSection">
    <img className="benefitsSection__cloudMobile" src="/images/cloudCalendarMobile.png" alt="cloud" />
    <img className="benefitsSection__ellipseMobile" src="/images/EllipseCalendarMobile.png" alt="ellipse" />
    <img className="benefitsSection__cloudBottomMobile" src="/images/cloudCalendarMobileBottom.png" alt="cloud" />
    <img className="benefitsSection__ellipseBottomMobile" src="/images/EllipseBenefitsMobile.png" alt="ellipse" />
    <img
      className="benefitsSection__ellipseBottomRightMobile"
      src="/images/EllipseBenegitsBottomRightMobile.png"
      alt="ellipse"
    />
    <div className="benefitsSection__wrapper">
      <div className="benefitsSection__content">
        <div className="benefitsSection__leftSide">
          <img className="benefitsSection__calendar" src="/images/calendar.png" alt="calendar" />
          <img className="benefitsSection__phone" src="/images/phone2.png" alt="calendar" />
        </div>
        <LocalizationContext.Consumer>
          {({ getWord }) => (
            <div className="benefitsSection__rightSide">
              <div className="benefitsSection__benefitContainer">
                <img src="/images/money.png" alt="money" />
                <p className="benefitsSection__title">{getWord('money')}</p>
              </div>
              <div className="benefitsSection__benefitContainer">
                <img src="/images/time.png" alt="time" />
                <p className="benefitsSection__title">{getWord('time')}</p>
              </div>
              <div className="benefitsSection__benefitContainer">
                <img src="/images/Saly-42.png" alt="plans" />
                <p className="benefitsSection__title">{getWord('plans')}</p>
              </div>
              <div className="benefitsSection__benefitContainer">
                <img src="/images/Saly-31.png" alt="customer" />
                <p className="benefitsSection__title">{getWord('customers')}</p>
              </div>
            </div>
          )}
        </LocalizationContext.Consumer>
      </div>
    </div>
  </section>
);
