import React, { FC } from 'react';

import { LocalizationContext } from '../../localization';
import env from '../../environment.json';

import './styles.css';

export const TopSection: FC = () => {
  const openAppStore = () => window.open(env.appStore);

  const openGooglePlay = () => window.open(env.googlePlay);

  return (
    <LocalizationContext.Consumer>
      {({ getWord }) => (
        <section className="topSection">
          <img className="ellipseMobile" src="/images/EllipseMobile.png" alt="ellips" />
          <img className="cloudMobile" src="/images/cloud.svg" alt="cloud" />
          <div className="topSection__wrapper">
            <img className="cloudLeft" src="/images/cloud2.svg" alt="cloud" />
            <div className="topSection__content">
              <div className="topSection__leftColumn">
                <h1 className="h1">{getWord('h1')}</h1>
                <p className="p">{getWord('find_clients')}</p>
                <div className="appWrapper">
                  <div onClick={openAppStore} className="appWrapper__button hover">
                    <img className="apple" src="/images/apple.svg" alt="apple" />
                    <div className="titleContainer">
                      <p className="titleContainer__subtitle">{getWord('app_store')}</p>
                      <p className="titleContainer__name">App Store</p>
                    </div>
                  </div>
                  <div onClick={openGooglePlay} className="appWrapper__button hover">
                    <img className="google" src="/images/play.png" alt="Google Play" />
                    <div className="titleContainer">
                      <p className="titleContainer__subtitle">{getWord('google_play')}</p>
                      <p className="titleContainer__name">Google Play</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="topSection__rightColumn">
                <div className="phoneWrapper">
                  <img className="phone" src="/images/phone.png" alt="apple" />
                  <div className="message messageTop">
                    <p>{getWord('bubbles_0')}</p>
                    <img className="avatar" src="/images/avatar1.png" alt="apple" />
                  </div>
                  <div className="message messageMiddle">
                    <p>
                      {getWord('bubbles_1_1')}
                      <br />
                      {getWord('bubbles_1_2')}
                    </p>
                    <img className="avatar" src="/images/avatar2.png" alt="apple" />
                  </div>
                  <div className="messageBottom">
                    <img className="rate" src="/images/rate.png" alt="rate" />
                    <img className="rateMobile" src="/images/rateMobile.png" alt="rate" />
                    <img className="avatar" src="/images/avatar1.png" alt="apple" />
                  </div>
                  <img className="cloud" src="/images/cloud.svg" alt="cloud" />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </LocalizationContext.Consumer>
  );
};
